import React, { FC } from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { Layout } from '../components/Layout';
import { PageProps } from 'gatsby';

import { SEO } from '../components/SEO';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

import '../styles/blog-template.scss';

type PageContextBlogTemplate = {
  skip: number;
  limit: number;
  currentPage: number;
  isFirst: boolean;
  isLast: boolean;
};

const BlogTemplate: FC<
  PageProps<GatsbyTypes.BlogTemplateQuery, PageContextBlogTemplate>
> = ({ data, location, pageContext }) => (
  <Layout>
    <SEO
      pagetitle="ブログ"
      pagedesc="shimu.devのブログです"
      pagepath={location.pathname}
    />
    <section className="blog-template">
      <h1 className="blog-template__title">RECENT BLOGS</h1>
      <div>
        <div className="posts">
          {data.allContentfulBlogPost.edges.map(({ node }) => (
            <article className="blog-template__card" key={node.id}>
              <Link
                className="blog-template__card-link"
                to={`/blog/post/${node.slug}/`}
              >
                <div className="blog-template_card-header">
                  <figure>
                    {node?.eyecatch?.fluid ? (
                      <Img
                        fluid={node.eyecatch.fluid}
                        alt={node.eyecatch.description}
                        style={{ width: '100%', height: '300px' }}
                      />
                    ) : (
                      ''
                    )}
                  </figure>
                  <div className="blog-template__card-header-content">
                    <h3 className="blog-template__card-date">
                      DATE {node.publishDateJP}
                    </h3>
                    <h2 className="blog-template__card-title">{node.title}</h2>
                  </div>
                </div>
                <div className="blog-template_card-body">
                  <p className="blog-template__card-desc">
                    {`${node?.contentMarkdown?.childMarkdownRemark?.excerpt}`}
                  </p>
                </div>
              </Link>
            </article>
          ))}
        </div>
        <ul className="blog-template__pagination">
          {!pageContext.isFirst && (
            <li className="prev">
              <Link
                className="blog-template__pagination-link"
                to={
                  pageContext.currentPage === 2
                    ? `/blog/`
                    : `/blog/${pageContext.currentPage - 1}`
                }
                rel="prev"
              >
                <FontAwesomeIcon icon={faChevronLeft} />
                <span>前のページ</span>
              </Link>
            </li>
          )}
          {!pageContext.isLast && (
            <li className="next">
              <Link
                className="blog-template__pagination-link"
                to={`/blog/${pageContext.currentPage + 1}/`}
                rel="next"
              >
                <span>次のページ</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </li>
          )}
        </ul>
      </div>
    </section>
  </Layout>
);

export const query = graphql`
  query BlogTemplate($skip: Int!, $limit: Int!) {
    allContentfulBlogPost(
      sort: { order: DESC, fields: publishDate }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          title
          id
          slug
          publishDateJP: publishDate(formatString: "YYYY月MM月DD日")
          contentMarkdown {
            childMarkdownRemark {
              excerpt(pruneLength: 70)
            }
          }
          eyecatch {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid_withWebp
            }
            description
          }
        }
      }
    }
  }
`;
export default BlogTemplate;
